import React, {useState, useEffect} from "react";
import {Collapse, Alert, IconButton, AlertProps, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ClosablePersistentAlertProps extends AlertProps {
  localStorageKey: string;
}

type localstorageExpiryItem = {
  value: any;
  expiry: number;
};

const ttl90days = 7776000;

const ClosablePersistentAlert: React.FC<ClosablePersistentAlertProps> = (props) => {
  const {localStorageKey, children, ...alertProps} = props;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const isOpen = shouldOpen();
    setIsOpen(isOpen);
  }, [localStorageKey]);

  const shouldOpen = () => {
    const storedValue = localStorage.getItem(props.localStorageKey);
    if (storedValue === null) {
      return true;
    }

    const localstorageExpiryItem: localstorageExpiryItem | false = JSON.parse(storedValue);
    // si pb de deserialisation
    if (localstorageExpiryItem === false) {
      return true;
    }

    const now = new Date();
    if (now.getTime() > localstorageExpiryItem.expiry) {
      return true;
    }

    if (localstorageExpiryItem.value === false) {
      return false;
    }

    return true;
  };

  const handleClose = () => {
    setIsOpen(false);

    const now = new Date();
    const item = {
      value: false,
      expiry: now.getTime() + ttl90days,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(item));
  };

  return (
    <Collapse in={isOpen} style={{paddingBottom: isOpen ? theme.palette.paddingBottom : 0}}>
      <Alert
        variant="outlined"
        {...alertProps}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {children}
      </Alert>
    </Collapse>
  );
};

export default ClosablePersistentAlert;
