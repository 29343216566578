import {Alert, IconButton} from "@mui/material";
import {GridCloseIcon} from "@mui/x-data-grid";

import ToastService from "./ToastService";
import {useSnackbar} from "notistack";

function Toast() {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  ToastService.getSubject().subscribe({
    next: (toastMessage) => {
      enqueueSnackbar({
        variant: "default",
        autoHideDuration: 4000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        content: (key) => (
          <Alert id="toaster" severity={toastMessage.severity} sx={{width: "100%"}}>
            {toastMessage.label}
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
              <GridCloseIcon fontSize="small" />
            </IconButton>
          </Alert>
        ),
      });
    },
  });

  ToastService.getCloseSubject().subscribe({
    next: () => {
      closeSnackbar();
    },
  });

  return <></>;
}

export default Toast;
