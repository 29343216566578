import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  Chip,
  useTheme,
} from "@mui/material";
import {AlarmInfo} from "../../../interfaces/AlarmInfo";
import CloseIcon from "@mui/icons-material/Close";
import {duration, formatDateTime} from "../../../service/FormatDateTime";
import {eventLevelColor} from "./AlarmService";
import dayjs from "dayjs";

type AlarmDialogProps = {
  open: boolean;
  onClose: () => void;
  alarmInfo: AlarmInfo;
};

export function AlarmDialog(props: AlarmDialogProps) {
  const theme = useTheme();

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        {props.alarmInfo.label}
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {/* Alarm Details Section */}
        <Typography sx={{fontSize: "16px"}} fontWeight="bold" gutterBottom>
          Détails de l'alarme
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="body2" fontWeight="bold">
              Code
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Chip
              label={props.alarmInfo.reference}
              sx={{backgroundColor: eventLevelColor(props.alarmInfo.level, theme), color: "#000", fontWeight: "bold"}}
            />
          </Grid>
          <AlarmDialogRow
            label="Description"
            value={
              props.alarmInfo.description === "" ? "Aucune information complémentaire" : props.alarmInfo.description
            }
          />
          <AlarmDialogRow
            label="Dépannage"
            value={
              props.alarmInfo.technicalTroubleshooting === ""
                ? "Aucune information complémentaire"
                : props.alarmInfo.technicalTroubleshooting
            }
          />
        </Grid>
        {/* Occurrence Section */}
        <Box mt={2}>
          <Typography sx={{fontSize: "16px"}} fontWeight="bold" gutterBottom>
            Occurrence
          </Typography>
          <Grid container spacing={1}>
            <AlarmDialogRow label="Date de début" value={formatDateTime(props.alarmInfo.beginDate)} />
            <AlarmDialogRow
              label="Date de fin"
              value={
                formatDateTime(props.alarmInfo.endDate) === "N/A"
                  ? "Alarme en cours ..."
                  : formatDateTime(props.alarmInfo.endDate)
              }
            />
            <AlarmDialogRow
              label="Durée"
              value={
                props.alarmInfo.endDate === null
                  ? duration(dayjs(props.alarmInfo.beginDate), dayjs())
                  : duration(dayjs(props.alarmInfo.beginDate), dayjs(props.alarmInfo.endDate))
              }
            />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="outlined" color="inherit">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AlarmDialogRow(props: {label: string; value: string}) {
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="body2" fontWeight="bold">
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">{props.value}</Typography>
      </Grid>
    </>
  );
}
