import {AlertColor} from "@mui/material";
import {Subject} from "rxjs";

interface ToastMessage {
  label: string;
  severity: AlertColor;
}

class ToastService {
  private static subject: Subject<ToastMessage> | null = null;
  private static closeSubject: Subject<boolean> | null = null;

  // Le constructeur de la classe doit rester privé afin de ne pas permettre
  // l'instanciation de la classe depuis l'extérieur.
  private constructor() {}
  public static getSubject(): Subject<ToastMessage> {
    if (this.subject === null) {
      this.subject = new Subject<ToastMessage>();
    }
    return this.subject;
  }
  public static getCloseSubject(): Subject<boolean> {
    if (this.closeSubject === null) {
      this.closeSubject = new Subject<boolean>();
    }
    return this.closeSubject;
  }
  public static info(label: string): void {
    this.getSubject().next({
      label: label,
      severity: "info",
    });
  }
  public static warning(label: string): void {
    this.getSubject().next({
      label: label,
      severity: "warning",
    });
  }
  public static error(label: string): void {
    this.getSubject().next({
      label: label,
      severity: "error",
    });
  }
  public static success(label: string): void {
    this.getSubject().next({
      label: label,
      severity: "success",
    });
  }
  public static close(): void {
    this.getCloseSubject().next(false);
  }
}

export default ToastService;
