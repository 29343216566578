import GA from "react-ga4";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./store/store";
import reportWebVitals from "./reportWebVitals";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import FleetManagement from "./app/fleet/FleetManagement";
import ListsClientSearchView from "./app/fleet/clients/ListsClientSearchView";
import ListsPlantSearchView from "./app/fleet/plants/ListsPlantSearchView";
import InstallationControlView from "./app/fleet/controls/installation-control/InstallationControlView";
import Platform from "./app/platform/Platform";
import ListsUserSearchView from "./app/platform/users/ListsUserSearchView";
import Sizea from "./app/pre-sale/sizea/Sizea";
import SizeaForm from "./app/pre-sale/sizea/SizeaForm";
import Implantation from "./app/pre-sale/implantation/Implantation";
import ImplantationView from "./app/pre-sale/implantation/ImplantationView";
import Scada from "./app/scada/Scada";
import PlantDataView from "./app/scada/plant/PlantDataView";
import ScadaRestartByAlarmView from "./app/scada/remote/ScadaRestartByAlarmView";
import Layout from "./Layout";
import AlarmsNotViewedBySavView from "./app/scada/alarms/AlarmsNotViewedBySavView";
import SoftwareView from "./app/resource/software/SoftwareView";
import Resource from "./app/resource/Resource";
import DocumentationView from "./app/resource/documentation/DocumentationView";
import PlantsDataView from "./app/scada/plants/PlantsDataView";
import SimulationList from "./app/pre-sale/sizea/SimulationList";
import Simulations from "./app/pre-sale/sizea/Simulations";
import {REACT_APP_GOOGLE_ANALYTICS_ID} from "./config";
import PlantsToControlView from "./app/fleet/controls/plants-to-control/PlantsToControlView";
import ImplantationV2 from "./app/pre-sale/implantation-v2/ImplantationV2";
import ImplantationViewV2 from "./app/pre-sale/implantation-v2/ImplantationViewV2";

GA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "fleet",
            element: <FleetManagement />,
            children: [
              {
                path: "client",
                element: <ListsClientSearchView />,
              },
              {
                path: "plant",
                element: <ListsPlantSearchView />,
              },
              {
                path: "control",
                element: <InstallationControlView />,
              },
              {
                path: "plants-to-control",
                element: <PlantsToControlView />,
              },
            ],
          },
          {
            path: "platform",
            element: <Platform />,
            children: [
              {
                path: "user",
                element: <ListsUserSearchView />,
              },
            ],
          },
          {
            path: "sizea",
            element: <Sizea />,
            children: [
              {
                path: "form",
                element: <SizeaForm />,
              },
              {
                path: "simulation",
                element: <SimulationList />,
              },
              {
                path: "simulations",
                element: <Simulations />,
              },
            ],
          },
          {
            path: "implantation",
            element: <ImplantationV2 />,
            children: [
              {
                path: "",
                element: <ImplantationViewV2 />,
              },
            ],
          },
          {
            path: "implantation-v1",
            element: <Implantation />,
            children: [
              {
                path: "",
                element: <ImplantationView />,
              },
            ],
          },
          {
            path: "scada",
            element: <Scada />,
            children: [
              {
                path: "plants/*",
                element: <PlantsDataView />,
              },
              {
                path: "plant/*",
                element: <PlantDataView />,
              },
              {
                path: "alarms/*",
                element: <AlarmsNotViewedBySavView />,
              },
              {
                path: "restart_by_alarm",
                element: <ScadaRestartByAlarmView />,
              },
            ],
          },
          {
            path: "resource",
            children: [
              {
                path: "software",
                element: <SoftwareView />,
              },
            ],
          },
          {
            path: "resource",
            element: <Resource />,
            children: [
              {
                path: "documentations",
                element: <DocumentationView />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
