import React, {useState, useEffect, useRef} from "react";
import GoogleMapReact from "google-map-react";
import {REACT_APP_GOOGLE_MAP_KEY} from "../../config";
import html2canvas from "html2canvas";
import {Box} from "@mui/material";

export interface handleApiLoadedArg {
  map: google.maps.Map;
  maps: typeof google.maps;
}

interface MapAddressProps {
  latitude: number | null;
  longitude: number | null;
  precision: number;
  onChange?: (position: {lat: number; lng: number}) => void;
  mapHeight: string;
  mapWidth: string;
  onCapture?: (data: HTMLCanvasElement) => void;
  handleApiLoaded?: (data: handleApiLoadedArg) => void;
  zoom?: number;
}

const GOOGLE_MAPS_API_KEY = REACT_APP_GOOGLE_MAP_KEY;

const MapAddress: React.FC<MapAddressProps> = (props: MapAddressProps) => {
  const mapHeight = props.mapHeight;
  const mapWidth = props.mapWidth;
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const defaultProps = {
    center: {
      lat: 46.755, // centre de la France
      lng: 1.83,
    },
    zoom: 5,
  };

  const roundToPrecision = (value: number, precision: number) => {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  };

  const [markerPosition, setMarkerPosition] = useState({
    lat: roundToPrecision(props.latitude || defaultProps.center.lat, props.precision),
    lng: roundToPrecision(props.longitude || defaultProps.center.lng, props.precision),
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    if (props.latitude === null && props.longitude === null) {
      const newPosition = {
        lat: defaultProps.center.lat,
        lng: defaultProps.center.lng,
      };
      setMarkerPosition(newPosition);
      return;
    }
    const newPosition = {
      lat: roundToPrecision(props.latitude as number, props.precision),
      lng: roundToPrecision(props.longitude as number, props.precision),
    };
    setMarkerPosition(newPosition);
    props.onChange && props.onChange(newPosition);
  }, [props.latitude, props.longitude, props.precision]);

  const handleApiLoaded = ({map, maps}: {map: google.maps.Map; maps: typeof google.maps}) => {
    mapRef.current = map;
    markerRef.current = new maps.Marker({
      position: markerPosition,
      map,
      draggable: true,
    });

    markerRef.current.addListener("dragend", (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const newPosition = {
          lat: roundToPrecision(event.latLng.lat(), props.precision),
          lng: roundToPrecision(event.latLng.lng(), props.precision),
        };
        setMarkerPosition(newPosition);
        props.onChange && props.onChange(newPosition);
      }
    });

    if (props.handleApiLoaded) props.handleApiLoaded({map, maps});
  };

  useEffect(() => {
    if (!markerRef.current) {
      return;
    }
    markerRef.current.setPosition(markerPosition);
  }, [markerPosition]);

  const doCapture = () => {
    if (!mapContainerRef.current) {
      return;
    }

    const mapContainer = mapContainerRef.current;

    html2canvas(mapContainer, {
      backgroundColor: null,
      useCORS: true,
      height: mapContainer.clientHeight - 20,
    }).then((canvas: HTMLCanvasElement) => {
      if (!props.onCapture) return;
      props.onCapture(canvas);
    });
  };

  return (
    <Box
      ref={mapContainerRef}
      sx={{
        height: mapHeight,
        width: mapWidth,
      }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY,
          libraries: ["core", "places"],
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={props.zoom ?? defaultProps.zoom}
        center={props.latitude && props.longitude ? {lat: props.latitude, lng: props.longitude} : defaultProps.center}
        zoom={props.zoom ?? (props.latitude && props.longitude ? 13 : defaultProps.zoom)}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
        onTilesLoaded={() => doCapture()}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          mapTypeId: "satellite",
        }}></GoogleMapReact>
    </Box>
  );
};

export default MapAddress;
