import * as React from "react";
import {Card, useTheme} from "@mui/material";

type Props = {
  children: React.ReactNode;
};

export default function AppCard(props: Props): React.JSX.Element {
  const theme = useTheme();

  return (
    <Card
      style={{
        borderRadius: 10,
        boxShadow: "none",
        marginBottom: theme.palette.paddingBottom, // card veut un margin pour faire un espace entre le card et son contenu
      }}>
      {props.children}
    </Card>
  );
}
