import React, {RefObject, useImperativeHandle, ForwardRefRenderFunction, useEffect} from "react";
import {SubmitHandler, Controller, useForm, useFieldArray} from "react-hook-form";

import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from "@mui/material/Grid";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import ErrorIcon from "@mui/icons-material/Error";

import Button from "@mui/material/Button";

import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import AutocompleteClient from "../../../component/form/AutocompleteClient";
import AutocompleteAddress from "../../../component/form/AutocompleteAddress";
import SelectAgency from "../../../component/form/SelectAgency";
import SelectInstallator from "../../../component/form/SelectInstallator";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {CreatePlantTracker} from "../../../interfaces/plant/PlantTracker";
import SelectTrackerType from "../../../component/form/SelectTrackerType";

import image from "../../../assets/icon_tracker.svg";

import apiV2 from "../../../service/api/ApiV2";
import ToastService from "../../../service/ToastService";
import {Divider, Typography} from "@mui/material";

import {CreatePlant, UpdatePlant} from "../../../interfaces/plant/Plant";
import {PlatformTypeEnum} from "../../../interfaces/client/PlatformTypeEnum";
import {ConsumptionLevelEnum} from "../../../interfaces/plant/ConsumptionLevelEnum";

import {PlantSearch} from "../../../interfaces/PlantSearch";
import {ClientSearch} from "../../../interfaces/client/ClientSearch";

import Tooltip from "@mui/material/Tooltip";
import ConfirmDialogService from "../../../service/ConfirmDialogService";

import Box from "@mui/material/Box";
import {StatusCodes} from "http-status-codes";
import AutocompletePlantTag from "../../../component/form/AutocompletePlantTag";
import ControlledSelect from "../../../component/form/ControlledSelect";
import {getListConsumptionLevel} from "../../../service/FormatConsumptionLevel";
import AppCustomWidthTooltip from "../../../component/AppCustomWidthTooltip";

type PlantFormProp = {
  contentTextStep1: string;
  contentTextStep2: string;
  activeStep: number;
  steps: string[];
  formRef: RefObject<PlantFormHandle>;
  setIsValidInput: (value: boolean) => void;
  handleUpdateRowPlant: (plantSearch: PlantSearch) => void;
  handleSetActiveStep: (activeStep: number) => void;
  handleCloseDialog: () => void;
  plantSearchUpdated: PlantSearch | null;
};

type PlantFormHandle = {
  submitForm: () => void;
};

type deleteTrackerTooltipType = {
  id: number | null;
  operationDate?: string | null;
};

const PlantForm: ForwardRefRenderFunction<PlantFormHandle, PlantFormProp> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {formRef, setIsValidInput} = props;

  const {
    control,
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
    reset,
    setError,
    formState: {errors, isValid},
  } = useForm<CreatePlant | UpdatePlant>({
    defaultValues: {
      name: "",
      clientId: null,
      agencyId: null,
      installatorId: null,
      placeId: "",
      address: "",
      latitude: null,
      longitude: null,
      consumptionLevel: ConsumptionLevelEnum.DOWNSTREAM_PLUS,
      restrictedPower: true,
      restrictedValue: 0,
      energyCalculationMethod: true,
      trackers: [
        {
          platformType: PlatformTypeEnum.OKWIND,
          trackerTypeId: null,
        },
      ],
      serialNumber: null,
      tags: [],
      canGenerateApiKey: false,
    },
  });

  const {
    fields: fieldsTrackers,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "trackers",
  });

  const watchFieldTrackersArray = watch("trackers");
  const controlledFieldsTrackers = fieldsTrackers.map((fieldsTrackers, index) => {
    return {
      ...fieldsTrackers,
      ...watchFieldTrackersArray[index],
    };
  });

  const isValidRangeRestrictedValue = (restrictedValue: number) =>
    !(restrictedValue <= 99 && restrictedValue >= -99 && restrictedValue !== 0);

  const deleteTrackerTooltipTitle = (item: deleteTrackerTooltipType, index: number) => {
    if (item.operationDate === null || item.id === null) {
      return "Supprimer le tracker n°" + (index + 1);
    }

    return index === 0
      ? "L'installation doit contenir au moins 1 tracker"
      : "Tracker déjà mis en route, contactez un administrateur";
  };

  const serverSideValidForm = (response: Response, errorMessage: string) => {
    if (response.status === StatusCodes.BAD_REQUEST) {
      response.json().then((resp) => {
        const errorsServerSide = JSON.parse(resp.detail);
        if (errorsServerSide.input === "serialNumber") {
          props.handleSetActiveStep(0);
          setError("serialNumber", {
            type: "server",
            message: errorsServerSide.message,
          });
        }
      });
    } else {
      ToastService.error(errorMessage);
    }
  };

  const onSubmit: SubmitHandler<CreatePlant | UpdatePlant> = (data) => {
    // Empty serial number should send null value to API
    data.serialNumber = data.serialNumber?.trim() || null;
    data.installatorId = data.installatorId === "" ? null : data.installatorId;

    if ("id" in data) {
      apiV2.updatePlant(data).then((response) => {
        if (response.ok) {
          setIsValidInput(isValid);
          const newPlantSearchUpdated = {} as PlantSearch;
          newPlantSearchUpdated.id = data.id;
          newPlantSearchUpdated.name = data.name;
          newPlantSearchUpdated.address = data.address;
          newPlantSearchUpdated.operationDate = props?.plantSearchUpdated
            ? props?.plantSearchUpdated?.operationDate
            : null;
          newPlantSearchUpdated.clients = props?.plantSearchUpdated ? props.plantSearchUpdated?.clients : [];
          newPlantSearchUpdated.serialNumber = data.serialNumber ?? null;
          newPlantSearchUpdated.tags = data.tags;

          // si le client principal a été modifié alors on reattribut le nouveau client principal
          if (data.clientId && data.clientId !== props.plantSearchUpdated?.clients[0].id) {
            apiV2.detailClient(data.clientId).then((data) => {
              const currentClientSearch = {} as ClientSearch;
              currentClientSearch.id = data.id ? data.id : 0;
              currentClientSearch.lastName = data.lastName;
              currentClientSearch.firstName = data.firstName;
              currentClientSearch.email = data.email;
              newPlantSearchUpdated.clients[0] = currentClientSearch;
            });
          }

          props.handleCloseDialog();
          props.handleUpdateRowPlant(newPlantSearchUpdated);
          ToastService.success("L'installation " + data.name + " vient d'être mis à jour");
        } else {
          serverSideValidForm(response, "Erreur durant la modification de l'installation");
        }
      });
    } else {
      apiV2.createPlant(data).then((response) => {
        if (response.ok) {
          setIsValidInput(isValid);
          ToastService.success("L'installation " + data.name + " vient d'être créé");
          props.handleCloseDialog();
        } else {
          serverSideValidForm(response, "Erreur durant la modification de l'installation");
        }
      });
    }
  };

  useEffect(() => {
    // load data into form from api get detail call
    if (props.plantSearchUpdated) {
      apiV2.detailPlant(props.plantSearchUpdated.id).then(async (plant: UpdatePlant) => {
        reset(plant);
        await trigger().then((resp) => {
          setIsValidInput(resp);
        });
      });
    }
  }, []);

  const nbErrorsCurrentTabForm = () => Object.keys(errors).length;

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    await trigger().then((valid) => {
      if (valid) props.handleSetActiveStep(newValue);
    });
  };

  const handleAddTracker = () => {
    const tracker = {} as CreatePlantTracker;
    tracker.id = null;
    tracker.platformType = PlatformTypeEnum.OKWIND;
    tracker.trackerTypeId = "";
    tracker.operationDate = null;
    append(tracker);
  };

  const handleRemoveTracker = (trackerId: number | null, index: number) => {
    ConfirmDialogService.warning(
      "xs",
      "Supprimer le tracker T" + (index + 1),
      "Etes-vous sur de vouloir supprimer le tracker de l'installation ?",
      "<strong>Cette action est irréversible.</strong>",
      () => {
        if (trackerId !== null) {
          apiV2.deleteTracker(trackerId).then((response) => {
            if (response.ok) {
              ToastService.success("Le tracker a été supprimé");
              remove(index);
            } else {
              ToastService.error("Erreur durant la suppression du tracker");
            }
          });
        } else {
          remove(index);
        }
      },
    );
  };

  useEffect(() => {
    setIsValidInput(isValid);
  }, [isValid, setIsValidInput]);

  useImperativeHandle(formRef, () => ({
    submitForm() {
      setIsValidInput(isValid);
      trigger();
      if (props.steps.length - 1 === props.activeStep || props.plantSearchUpdated !== null) handleSubmit(onSubmit)();
    },
  }));

  return (
    <React.Fragment>
      {props.plantSearchUpdated === null ? (
        <Stepper activeStep={props.activeStep} style={{marginBottom: "20px"}}>
          {props.steps.map((label) => {
            const stepProps: {completed?: boolean} = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      ) : (
        <Tabs
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          value={props.activeStep}
          style={{marginBottom: "20px", marginTop: "-20px"}}>
          {props.steps.map((label, index) => {
            return (
              <Tab
                id={`updateTabButton${index}`}
                key={index}
                label={
                  index === props.activeStep && nbErrorsCurrentTabForm() > 0 ? (
                    <React.Fragment>
                      <Badge badgeContent={nbErrorsCurrentTabForm()} color="error">
                        <span style={{marginTop: "5px", marginRight: "5px"}}>{label}</span>
                        <ErrorIcon />
                      </Badge>
                    </React.Fragment>
                  ) : (
                    <span style={{marginTop: "5px", marginRight: "5px"}}>{label}</span>
                  )
                }
                value={index}
              />
            );
          })}
        </Tabs>
      )}

      <form onSubmit={handleSubmit(onSubmit)} data-cy={"plant-form"}>
        {props.activeStep === 0 && (
          <div>
            <Typography style={{marginBottom: 20}}>{props.contentTextStep1}</Typography>
            <Controller
              name="name"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  id="plantNameInput"
                  type="text"
                  variant="outlined"
                  color="primary"
                  placeholder="Nom de l'installation"
                  label="Nom de l'installation"
                  InputLabelProps={{shrink: true}}
                  required
                  {...register("name", {
                    required: "Le nom est obligatoire",
                    minLength: {
                      value: 2,
                      message: "Le nom doit contenir au moins 2 caractères",
                    },
                    maxLength: {
                      value: 50,
                      message: "Le nom ne peut dépasser 50 caractères",
                    },
                  })}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : null}
                />
              )}
            />

            <Controller
              name="serialNumber"
              control={control}
              render={({field}) => (
                <Box>
                  <TextField
                    {...field}
                    id="plantSerialNumberInput"
                    data-cy={`cypressPlantSerialNumberInput`}
                    type="text"
                    color="primary"
                    placeholder="Numéro de série de l'installation"
                    label={
                      <React.Fragment>
                        Numéro de série de l'installation
                        <AppCustomWidthTooltip
                          title="Toute installation est déjà connue et enregistrée dans un outil de relation client (CRM).
                                                     Renseignez ici l'identifiant de l'installation issu de cet outil, afin de faciliter la correspondance.
                                                     Exemple de n° de série : S012345">
                          <HelpOutlineIcon style={{marginBottom: "-5px", marginLeft: "5px"}} />
                        </AppCustomWidthTooltip>
                      </React.Fragment>
                    }
                    InputLabelProps={{shrink: true}}
                    required={!props.plantSearchUpdated}
                    {...register("serialNumber", {
                      required: !props.plantSearchUpdated ? "Le numéro de série est obligatoire" : false,
                      maxLength: {
                        value: 50,
                        message: "Le numéro de série de l'installation doit contenir au maximum 50 caractères",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9_\-#]+$/,
                        message:
                          "Caractères autorisés : Alphanumérique (A-z,0-9) + caractères spéciaux suivants : “-”  ”_” “#”",
                      },
                    })}
                    fullWidth
                    error={!!errors.serialNumber}
                    helperText={errors.serialNumber ? errors.serialNumber.message : null}
                  />
                </Box>
              )}
            />

            <FormControl style={{marginBottom: "0px"}} fullWidth>
              <Controller
                name="clientId"
                control={control}
                render={({field}) => (
                  <AutocompleteClient
                    {...field}
                    placeholder={"Rechercher par nom, email, numéro de téléphone..."}
                    label="Client"
                    name={`clientId`}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    preloadValue={watch("clientId")}
                    options={{required: true}}
                    error={!!errors.clientId}
                    handleClientChange={(clientSearch) => {
                      if (!props.plantSearchUpdated && clientSearch !== null) {
                        if (clientSearch.platformType === "lumioo") {
                          setValue("energyCalculationMethod", false);
                        } else {
                          setValue("energyCalculationMethod", true);
                        }
                      }
                    }}
                  />
                )}
              />
            </FormControl>
            <Stack spacing={2} direction={{xs: "column", md: "row"}} sx={{marginBottom: 0, marginTop: "-20px"}}>
              <FormControl fullWidth>
                <SelectAgency
                  sx={{width: "100%"}}
                  name={`agencyId`}
                  label={"Agence"}
                  register={register}
                  preloadValue={watch("agencyId")}
                  options={{required: true}}
                  error={!!errors.agencyId}
                  helpertext={errors.agencyId ? "L'agence est obligatoire" : null}
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <SelectInstallator
                  style={{width: "100%"}}
                  name={`installatorId`}
                  label="Installateur prestataire"
                  register={register}
                  preloadValue={watch("installatorId")}
                  options={{required: false}}
                  error={!!errors.installatorId}
                  setValue={setValue}
                />
              </FormControl>
            </Stack>
            <FormControl style={{marginBottom: "0px"}} fullWidth>
              <Controller
                name="address"
                control={control}
                render={({field}) => (
                  <AutocompleteAddress
                    {...field}
                    placeholder={"Rechercher l'adresse par ville, rue..."}
                    label="Adresse"
                    preloadvalue={watch("address")}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    address={`address`}
                    placeId={`placeId`}
                    latitude={`latitude`}
                    longitude={`longitude`}
                    options={{required: true}}
                    error={!!errors.address}
                    style={{marginBottom: "0px"}}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth style={{marginTop: "-20px"}}>
              <Controller
                name="canGenerateApiKey"
                control={control}
                render={({field: {onChange, value}}) => (
                  <div>
                    <FormControlLabel
                      style={{marginTop: "-15px"}}
                      control={<Switch checked={value} onChange={onChange} />}
                      label="Activer l'API Client"
                    />
                    <AppCustomWidthTooltip
                      title={
                        <div style={{fontSize: "10px", whiteSpace: "pre-line"}}>
                          {
                            "Pré-requis : le client a souscrit à l'offre 'API Client'.\n Permet au client de générer une clé API depuis son espace client, et d'intégrer ses données à son environnement informatique."
                          }
                        </div>
                      }>
                      <HelpOutlineIcon style={{width: "18px", color: "grey"}} />
                    </AppCustomWidthTooltip>
                  </div>
                )}
              />
            </FormControl>
            <FormControl fullWidth style={{marginTop: "-10px"}}>
              <AutocompletePlantTag
                preSelectedTags={getValues("tags")}
                onChange={(newValue) => {
                  setValue(
                    "tags",
                    newValue.map((selectedTag) => selectedTag.label),
                  );
                }}
                isCreatable={true}
              />
            </FormControl>

            <Typography style={{marginTop: "-20px", marginBottom: "10px"}} color="text.secondary">
              Caractéristiques techniques
            </Typography>

            <ControlledSelect
              required
              fullWidth
              name={"consumptionLevel"}
              size={"medium"}
              style={{marginTop: 10}}
              label={"Mesure de consommation"}
              id={"consumptionLevel"}
              control={control}
              tooltipLabelText="BIDIRECTIONNEL + : L'onduleur est raccordé en aval de la mesure de consommation
                                BIDIRECTIONNEL - :  L'onduleur est raccordé en aval de la mesure de consommation inversée
                                MONODIRECTIONNEL + : L'onduleur est raccordé en amont de la mesure de consommation
                                MONODIRECTIONNEL - : L'onduleur est raccordé en amont de la mesure de consommation inversée"
              TooltipIcon={HelpOutlineIcon}
              variant={"outlined"}
              options={[
                ...getListConsumptionLevel().map((consumptionLevel) => ({
                  value: consumptionLevel.value,
                  label: consumptionLevel.label,
                })),
              ]}
            />

            <Controller
              name="energyCalculationMethod"
              control={control}
              render={({field}) => (
                <FormControl>
                  <FormLabel>
                    Méthode de calcul
                    <AppCustomWidthTooltip title="Certains onduleurs n'ont pas une précision de mesure d'énergie (Watt-Heure) suffisante pour permettre un affichage correct des données de consommation/production à la minute (Donnée sous forme de palier). Pour corriger ce problème, vous pouvez choisir d'utiliser les valeurs de Puissance (Watt). (Essentiellement utilisé pour les trackers Lumioo)">
                      <HelpOutlineIcon
                        style={{
                          marginBottom: "-5px",
                          marginLeft: "5px",
                          width: "18px",
                        }}
                      />
                    </AppCustomWidthTooltip>
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    onChange={(e) => {
                      setValue("energyCalculationMethod", JSON.parse(e.target.value));
                    }}
                    style={{marginTop: "0px", marginBottom: "0px"}}
                    row
                    defaultValue={true}
                    aria-labelledby="PlantEnergyCalculationMethodRadioGroup">
                    <FormControlLabel value={true} control={<Radio />} label="Calcul en énergie (Wh)" />
                    <FormControlLabel value={false} control={<Radio />} label="Calcul en puissance (W)" />
                  </RadioGroup>
                </FormControl>
              )}
            />
            <FormGroup>
              <Stack spacing={2} direction={{xs: "column", md: "row"}} sx={{marginBottom: 0}}>
                <Controller
                  name="restrictedPower"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <FormControlLabel
                      style={{marginTop: "-15px"}}
                      control={<Switch checked={value} onChange={onChange} />}
                      label="Bridage à l'injection"
                    />
                  )}
                />
                <Controller
                  name="restrictedValue"
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      type="number"
                      style={{maxWidth: "324px"}}
                      inputProps={{
                        step: 100,
                      }}
                      id="plantRestrictedValueInput"
                      color="primary"
                      placeholder="Valeur de bridage (en W)"
                      label="Valeur de bridage (en W)"
                      InputLabelProps={{shrink: true}}
                      required
                      disabled={!watch("restrictedPower")}
                      error={!!errors.restrictedValue}
                      {...register("restrictedValue", {
                        valueAsNumber: true,
                        validate: (data) => {
                          if (typeof data !== "number" || isNaN(data)) {
                            // restricted value is mandatory only if restricted power is enabled
                            if (getValues("restrictedPower")) {
                              return "La valeur de bridage est obligatoire";
                            } else {
                              return true;
                            }
                          }
                          return (
                            isValidRangeRestrictedValue(data) ||
                            "La valeur de bridage doit être égale à 0 ou au minimun + ou - 100 W"
                          );
                        },
                      })}
                      fullWidth
                      helperText={errors.restrictedValue ? errors.restrictedValue.message : null}
                    />
                  )}
                />
              </Stack>
            </FormGroup>
          </div>
        )}
        {props.activeStep === 1 && (
          <div>
            <Typography style={{marginBottom: 20}}>{props.contentTextStep2}</Typography>
            <List
              style={{
                minWidth: !isMobile ? "550px" : "100%",
                minHeight: "500px",
                maxHeight: "200px",
              }}>
              {controlledFieldsTrackers.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <Stack direction="column">
                          {!isMobile && (
                            <ListItemIcon>
                              <img
                                style={{
                                  width: "48px",
                                  marginTop: "-15px",
                                  marginLeft: "5px",
                                }}
                                src={image}
                                alt="Logo Okwind"
                              />
                            </ListItemIcon>
                          )}
                          <span
                            style={{
                              marginTop: isMobile ? "5px" : "0px",
                              marginBottom: isMobile ? "10px" : "0px",
                            }}>
                            Tracker {item.nTrk ? item.nTrk : index + 1}
                            {isMobile && (
                              <IconButton
                                id="deleteTrackerButtonDialog"
                                color="error"
                                aria-label="open remove tracker"
                                edge="start"
                                disabled={
                                  (controlledFieldsTrackers.length === 1 || !(item.operationDate === null)) &&
                                  !(item.id === null)
                                }
                                onClick={() => handleRemoveTracker(item.id, index)}
                                style={{
                                  marginTop: "-10px",
                                  float: "right",
                                }}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            )}
                          </span>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel style={{marginLeft: "10px"}} required>
                            Type de plateforme
                          </InputLabel>
                          <Select
                            style={{width: "100%", marginRight: "10px"}}
                            value={watch(`trackers.${index}.platformType`)}
                            disabled={
                              item.id !== null && item.operationDate !== null && props.plantSearchUpdated !== null
                            }
                            label="Type de plateforme"
                            data-cy={`cypressSelectPlatformType${index}`}
                            {...register(`trackers.${index}.platformType`, {
                              onChange: () => {
                                setValue(`trackers.${index}.trackerTypeId`, null);
                              },
                            })}>
                            <MenuItem
                              data-cy={`cypressSelectPlatformTypeOptionOkwind${index}`}
                              value={PlatformTypeEnum.OKWIND}>
                              Okwind
                            </MenuItem>
                            <MenuItem
                              data-cy={`cypressSelectPlatformTypeOptionLumioo${index}`}
                              value={PlatformTypeEnum.LUMIOO}>
                              Lumioo
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FormControl fullWidth>
                          <SelectTrackerType
                            error={!!errors.trackers?.[index]?.trackerTypeId}
                            style={{width: "100%", marginRight: "10px"}}
                            name={`trackers.${index}.trackerTypeId`}
                            register={register}
                            options={{
                              required: item.id === null || props.plantSearchUpdated === null,
                            }}
                            platformtype={watch(`trackers.${index}.platformType`)}
                            preloadvalue={watch(`trackers.${index}.trackerTypeId`)}
                            required={item.id === null || props.plantSearchUpdated === null}
                            helpertext="La référence est obligatoire"
                          />
                        </FormControl>
                      </Grid>
                      {!isMobile && (
                        <Grid item xs={12} md={1}>
                          <Tooltip title={deleteTrackerTooltipTitle(item, index)}>
                            <span>
                              <IconButton
                                id={`deleteTrackerButtonDialog${index}`}
                                color="error"
                                aria-label="open remove tracker"
                                edge="start"
                                disabled={
                                  (controlledFieldsTrackers.length === 1 || !(item.operationDate === null)) &&
                                  !(item.id === null)
                                }
                                onClick={() => handleRemoveTracker(item.id, index)}
                                style={{
                                  marginTop: "10px",
                                }}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      )}
                      {index < controlledFieldsTrackers.length - 1 && (
                        <Grid item xs={12}>
                          <Divider
                            style={{
                              marginBottom: "10px",
                              marginTop: "-10px",
                            }}></Divider>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                );
              })}
              <Button
                style={{float: "right", marginRight: "10px"}}
                id="addTrackerButton"
                variant="outlined"
                onClick={handleAddTracker}
                startIcon={<AddIcon />}>
                Ajouter un tracker
              </Button>
            </List>
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

export default React.forwardRef(PlantForm);
