import {useEffect, useRef, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {Box, FormControlLabel, FormGroup, FormHelperText, Grid, Link, Stack, Switch, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import AutocompleteAddress from "../../../component/form/AutocompleteAddress";
import MapAddress, {handleApiLoadedArg} from "../../../component/form/MapAddress";
import {MatType} from "./component/MatType";
import {RoughnessList} from "./component/RoughnessList";
import {Roughness} from "./model/Roughness";
import {ImplantationResponse, ImplantationRequest, PoleTypeEnum, TrackerTypeEnum, RougnessRequest} from "./model";
import {apiImplantation} from "../../../service/api/ApiImplantation";
import Result from "./Result";
import {TrackerList} from "./component/TrackerList";
import RoughnessDialog from "./component/RoughnessDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AppButton from "../../../component/atom/button/AppButton";
import ClosablePersistentAlert from "../../../component/molecules/DismissibleAlert";
import ToastService from "../../../service/ToastService";
import AppCard from "../../../component/atom/card/AppCard";

export interface ImplantationData {
  address: string;
  latitude: number | null;
  longitude: number | null;
  trackerType: "" | TrackerTypeEnum;
  matType: PoleTypeEnum | null;
  addraise: boolean;
  sectors: Roughness | null[];
}

export default function Form() {
  const [selectedRoughness, setSelectedRoughness] = useState<Roughness | null>(null);
  const [sectors, setSectors] = useState<Array<Roughness | null>>([]);
  const [gmaps, setGmaps] = useState<handleApiLoadedArg | null>(null);
  const [result, setResult] = useState<ImplantationResponse | null>(null);

  const selectedRoughnessRef = useRef<Roughness | null>(null);
  const sectorsRef = useRef<Array<Roughness | null>>([]);
  const gmapsRef = useRef<handleApiLoadedArg | null>(null);
  const polygonsRef = useRef<google.maps.Polygon[]>([]);

  const [roughnessDialogOpen, setRoughnessDialogOpen] = useState(false);

  useEffect(() => {
    resetSectors();
  }, []);

  useEffect(() => {
    selectedRoughnessRef.current = selectedRoughness;
  }, [selectedRoughness]);
  useEffect(() => {
    sectorsRef.current = sectors;
    validateSectors();
  }, [sectors]);
  useEffect(() => {
    gmapsRef.current = gmaps;
  }, [gmaps]);

  const getDefaultValues = () => {
    return {
      address: "",
      latitude: null,
      longitude: null,
      trackerType: "",
      matType: PoleTypeEnum.STANDARD,
      addraise: false,
      sectors: [],
    } satisfies ImplantationData;
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: {errors},
  } = useForm<ImplantationData>({
    defaultValues: getDefaultValues(),
  });

  const resetForm = () => {
    reset();

    setResult(null);
    resetSectors();
    clearPolygons();

    clearErrors();

    if (!gmapsRef.current) return;
    drawCircle(gmapsRef.current, watch("latitude"), watch("longitude"));
  };

  const resetSectors = () => {
    const sectors = Array(12).fill(null);
    setSectors(sectors);
  };

  const handlePositionChange = ({lat, lng}: {lat: number | null; lng: number | null}) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  useEffect(() => {
    resetSectors();
    clearPolygons();

    if (!gmapsRef.current) return;

    drawCircle(gmapsRef.current, Number(watch("latitude")), Number(watch("longitude")));
  }, [watch("latitude"), watch("longitude")]);

  useEffect(() => {
    if (!result) return;
    goToAnchor("results");
  }, [result]);

  const onRoughnessSelected = (roughness: Roughness) => {
    setSelectedRoughness(roughness);
  };

  const validateSectors = (): boolean => {
    if (getSectorsNotNullSize() === 12) {
      clearErrors(`sectors`);
      return true;
    }

    setError(`sectors`, {
      type: "manual",
      message: `Tous les secteurs doivent être renseignés`,
    });

    return false;
  };

  const clearPolygons = () => {
    polygonsRef.current.forEach((polygon) => {
      polygon.setMap(null); // Retire le polygone de la carte
    });
    polygonsRef.current = []; // Vide le tableau des polygones
  };

  const drawSector = (
    index: number,
    map: google.maps.Map,
    maps: typeof google.maps,
    center: {lat: number; lng: number},
    radius: number,
    startAngle: number,
    endAngle: number,
  ) => {
    const points = [];
    const numPoints = 30; // Nombre de points pour arrondir le secteur

    // Ajouter le centre du cercle comme premier point du polygone
    points.push(center);

    // Calcul des points sur la circonférence du cercle
    for (let i = 0; i <= numPoints; i++) {
      const angle = startAngle + (i * (endAngle - startAngle)) / numPoints;
      const point = {
        lat: center.lat + (radius * Math.cos(angle)) / 111320, // Conversion de mètres en latitude
        lng: center.lng + (radius * Math.sin(angle)) / (111320 * Math.cos(center.lat * (Math.PI / 180))), // Conversion de mètres en longitude
      };
      points.push(point);
    }

    const defaultFillColor = "#dddddd";

    // Créer un polygone pour le secteur
    const polygon = new maps.Polygon({
      paths: points,
      strokeColor: "#2fb109",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: defaultFillColor,
      fillOpacity: 0.5,
      map,
    });

    polygonsRef.current.push(polygon);

    polygon.addListener("click", () => {
      const roughness = selectedRoughnessRef.current;

      if (!roughness) return;

      const sectors = sectorsRef.current;
      sectors[index] = sectors[index] === roughness ? null : roughness;

      let newFillColor = defaultFillColor;
      if (sectors[index]) {
        newFillColor = sectors[index].color;
      }

      polygon.setOptions({
        fillColor: newFillColor,
      });

      setSectors([...sectors]);
    });

    return polygon;
  };

  const handleApiLoaded = (gmaps: handleApiLoadedArg) => {
    setGmaps(gmaps);
    drawCircle(gmaps, 49, 49);
  };

  const drawCircle = (gmaps: handleApiLoadedArg, lat: number | null, lng: number | null) => {
    if (lat === null || lng === null) return;

    const center = {lat, lng};
    const radius = 300; // Rayon en mètres

    // Diviser le cercle en 12 secteurs égaux
    const numSectors = 12;
    const angleStep = (2 * Math.PI) / numSectors;

    for (let i = 0; i < numSectors; i++) {
      const startAngle = i * angleStep;
      const endAngle = (i + 1) * angleStep;
      drawSector(i, gmaps.map, gmaps.maps, center, radius, startAngle, endAngle);
    }
  };

  const getSectorsNotNullSize = (): number => {
    return Object.values(sectors).filter((value) => value !== null).length;
  };

  const getSectorsSize = (): number => {
    return Object.keys(sectors).length;
  };

  const onSubmit: SubmitHandler<ImplantationData> = (data) => {
    if (validateSectors() === false) {
      return;
    }

    if (data.latitude === null || data.longitude === null) return;

    const apiData = {
      latitude: data.latitude,
      longitude: data.longitude,
      tracker_type: data.trackerType as TrackerTypeEnum,
      pole_type: getPoleType(data),
      roughness_lengths: getRoughnessLengths(sectors as unknown as Roughness[]),
    } satisfies ImplantationRequest;

    apiImplantation
      .simulate(apiData)
      .then((result) => {
        setResult(result);
      })
      .catch((error: Error) => {
        ToastService.error(error.message);
      });
  };

  const getPoleType = (data: ImplantationData): PoleTypeEnum => {
    // lumioo est forcement standard
    if (data.trackerType === "LUMIOO") {
      return PoleTypeEnum.STANDARD;
    }

    if (data.addraise) {
      // ajout d'une rehausse
      if (data.matType === PoleTypeEnum.SHORTENED) {
        return PoleTypeEnum.SHORTENED_EXTENDED;
      }
      return PoleTypeEnum.EXTENDED;
    }

    return data.matType as PoleTypeEnum;
  };

  const getRoughnessLengths = (roughnesses: Roughness[]): RougnessRequest[] => {
    return roughnesses.map((roughnessItem) => ({
      z0: roughnessItem.z0,
      zmin: roughnessItem.zmin,
    }));
  };

  const onError = () => {
    let anchorId = "map";
    if (errors.trackerType || errors.address || errors.latitude || errors.longitude || errors.matType) {
      anchorId = "beginning";
    }

    goToAnchor(anchorId);
  };

  const goToAnchor = (anchorId: string) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Box sx={{width: {xs: "100%", lg: "1100px"}}}>
      <ClosablePersistentAlert localStorageKey="introImplantationAlert" severity="info">
        Cette application permet de vérifier si une zone est apte à accueillir l'équipement sélectionné au regard des
        contraintes liées aux efforts de vent, à la neige et à la corrosion qu'il verra sur sa durée de vie.
      </ClosablePersistentAlert>

      <Link id="beginning" />
      <AppCard>
        <CardContent>
          <Grid container justifyContent={"center"} direction={"row"}>
            <form onSubmit={handleSubmit(onSubmit, onError)} style={{width: "100%"}}>
              <Divider textAlign="left">Quel modèle de tracker implanter ?</Divider>
              <Stack direction={{sm: "column", md: "row"}}>
                <Box padding={1}>
                  <FormControl error={!!errors.trackerType} sx={{width: {xs: "100%", lg: "640px"}}}>
                    {errors.trackerType && <FormHelperText>Veuillez sélectionner un modèle</FormHelperText>}
                    <Controller
                      control={control}
                      name="trackerType"
                      rules={{required: true}}
                      render={({field}) => <TrackerList {...field} />}
                    />
                  </FormControl>
                </Box>
                <Box padding={1}>
                  <Typography variant="body2" color="text.primary" fontWeight={"bold"}>
                    Ajustement en hauteur du modèle
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    La hauteur de la structure est prise en compte dans le calcul des efforts de vent et de neige.
                  </Typography>
                  {watch(`trackerType`) !== "LUMIOO" && (
                    <FormControl>
                      <MatType name="matType" error={!!errors.matType} control={control} />
                      <Controller
                        name="addraise"
                        control={control}
                        render={({field: {onChange, value}}) => (
                          <FormControlLabel
                            control={<Switch checked={value} onChange={onChange} />}
                            label="Ajout d'une réhausse"
                          />
                        )}
                      />
                    </FormControl>
                  )}
                  {watch(`trackerType`) === "LUMIOO" && (
                    <Box>
                      <FormControl>Aucun ajustement disponible pour ce modèle</FormControl>
                    </Box>
                  )}
                </Box>
              </Stack>
              <FormControl fullWidth>
                <Divider textAlign="left">A quel endroit ?</Divider>
                <FormControl style={{marginBottom: "0px", marginTop: 15}} fullWidth>
                  <Controller
                    name={`address`}
                    control={control}
                    render={({field}) => (
                      <AutocompleteAddress
                        {...field}
                        label="Adresse"
                        required={false}
                        preloadvalue={watch(`address`)}
                        register={register}
                        options={{required: false}}
                        setValue={setValue}
                        address={`address`}
                        latitude={`latitude`}
                        longitude={`longitude`}
                        error={!!errors.address}
                        latLngPrecision={4}
                        doNotLoadGoogleMapScript
                      />
                    )}
                  />
                  <Stack direction="row" alignItems="left" spacing={2}>
                    <FormGroup>
                      <Controller
                        name="latitude"
                        control={control}
                        rules={{
                          required: "La latitude est obligatoire",
                          validate: (value) =>
                            (value && value >= -90 && value <= 90) || "La latitude doit être comprise entre -90 et 90",
                        }}
                        render={({field}) => (
                          <TextField
                            {...field}
                            type="number"
                            inputProps={{
                              step: "0.0001",
                              value: field.value === null ? "" : field.value,
                            }}
                            label="Latitude"
                            error={!!errors.latitude}
                            helperText={errors.latitude ? errors.latitude.message : "4 chiffres après la virgule"}
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Controller
                        name="longitude"
                        control={control}
                        rules={{
                          required: "La longitude est obligatoire",
                          validate: (value) =>
                            (value && value >= -180 && value <= 180) ||
                            "La longitude doit être comprise entre -180 et 180",
                        }}
                        render={({field}) => (
                          <TextField
                            {...field}
                            type="number"
                            inputProps={{
                              step: "0.0001",
                              value: field.value === null ? "" : field.value,
                            }}
                            label="Longitude"
                            error={!!errors.longitude}
                            helperText={errors.longitude ? errors.longitude.message : "4 chiffres après la virgule"}
                          />
                        )}
                      />
                    </FormGroup>
                  </Stack>
                  <Link id="map" />
                  <Box
                    sx={{
                      paddingBottom: 1,
                    }}>
                    <MapAddress
                      latitude={watch("latitude")}
                      longitude={watch("longitude")}
                      onChange={handlePositionChange}
                      precision={4}
                      zoom={watch("latitude") && watch("longitude") ? 16 : undefined}
                      mapWidth="100%"
                      mapHeight="431px"
                      handleApiLoaded={(data: handleApiLoadedArg) => handleApiLoaded(data)}
                    />
                  </Box>
                  <Box>
                    <FormControl error={!!errors.sectors}>
                      {errors.sectors && (
                        <FormHelperText>
                          Nombre de secteurs saisis:
                          <span
                            style={{
                              margin: 4,
                            }}>
                            {getSectorsNotNullSize()}
                          </span>
                          /
                          <span
                            style={{
                              margin: 4,
                            }}>
                            {getSectorsSize()}
                          </span>
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Typography variant="body2" color="text.secondary">
                      Dans un rayon de 300m (le cercle sur la carte), sélectionnez les natures de sols présentes.
                      <br />
                      Choississez une typologie de sol ci-dessous et cliquez sur les sections du cerle correspondantes.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: 1,
                      display: "flex",
                      justifyContent: "space-around",
                    }}>
                    <RoughnessList
                      selectedRoughness={selectedRoughness}
                      onClick={(roughness: Roughness) => onRoughnessSelected(roughness)}
                      onOpenDialog={() => setRoughnessDialogOpen(true)}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginTop: 1,
                    }}>
                    <Link sx={{cursor: "pointer"}} variant="body2" onClick={() => setRoughnessDialogOpen(true)}>
                      <Stack alignItems="center" direction="row" gap={2}>
                        <HelpOutlineIcon />
                        <Typography variant="body1">Besoin d'aide pour choisir le bon type de sol ?</Typography>
                      </Stack>
                    </Link>
                  </Box>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}>
                  <Box padding={1}>
                    <AppButton label={"Lancer la vérification"} type="submit" />
                  </Box>
                  <Box padding={1}>
                    <AppButton
                      style="secondary"
                      label={"Réinitialiser"}
                      onClick={() => {
                        resetForm();
                      }}
                    />
                  </Box>
                </Box>
              </FormControl>
            </form>
          </Grid>
        </CardContent>
      </AppCard>
      {result && (
        <>
          <Link id="results" />
          <AppCard>
            <CardContent>
              <Result result={result} />
            </CardContent>
          </AppCard>
        </>
      )}
      <RoughnessDialog
        id={1}
        open={roughnessDialogOpen}
        selectedRoughness={selectedRoughness}
        onClose={() => {
          setRoughnessDialogOpen(false);
        }}
        onSelect={(roughness: Roughness) => onRoughnessSelected(roughness)}
      />
    </Box>
  );
}
